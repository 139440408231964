// TODO: 大きくなったら切り出す
import {
  ComponentStyleConfig,
  extendTheme,
} from '@chakra-ui/react';

const Drawer: ComponentStyleConfig = {
  variants: {
    alwaysOpen: {
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
};

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    icon: {
      animation: 'none',
      transitionProperty: 'none',
      transitionDuration: 0,
    },
  },
};

export const theme = extendTheme({
  initialColorModeName: 'light',
  useSystemColorMode: false,
  fonts: {
    heading: 'メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS P Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, arial, sans-serif, verdana, Helvetica',
    body: 'メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS P Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, arial, sans-serif, verdana, Helvetica',
  },
  styles: {
    global: () => ({
      body: {
        textStyle: 'h6',
        bg: 'gray.200',
        color: 'rerakuBrandSecondary.500',
      },
    }),
  },
  borderColor: '#000',
  colors: {
    gray: {
      500: '#58595B',
      400: '#A6A6A6',
      250: '#e1e1e1',
      200: '#f8f8f8',
      100: '#fcfcfc',
      50: '#CBCBCB',
    },
    pink: {
      100: '#ff6060'
    },
    lightGreen: {
      500: '#73BA1A'
    },
    deepGreen: {
      500: '#45612C',
      100: '#005F30'
    },
    brand: {
      500: '#82ae40',
      300: '#E7F6E2',
      100: '#d3e8b1'
    },
    brandSecondary: {
      500: '#553E26',
      300: 'rgba(85, 62, 38, 0.6)',
    },
    rerakuBrand: {
      500: '#82ae40',
      300: '#005F30',
      100: '#F1F8E8'
    },
    rerakuBrandSecondary: {
      500: '#553E26',
      300: 'rgba(85, 62, 38, 0.6)',
    },
    sparerakuBrand: {
      500: '#E94709',
      300: '#F39800',
      100: '#FCEBDB',
    },
    sparerakuSecondary: {
      500: '#B3AD4F',
    },
    bellepocBrand: {
      500: '#009944',
      300: '#45B035',
      100: '#E5F5EC',
    },
    bellepocSecondary: {
      500: '#EA5504',
      300: 'F6AB00'
    },
    ruamruamBrand: {
      500: '#604531',
      300: 'rgba(106, 67, 49, 0.4)',
      100: '#EFECEA'
    },
    ruamruamBrandSecondary: {
      500: '#003A2D'
    },
    ruamruamBrandThird: {
      500: '#EC6D7B'
    },
    rerakuproBrand: {
      500: '#CEDB27',
      300: '#FAFBE9'
    },
    rerakuproBrandSecondary: {
      500: '#111111',
      300: '#000000'
    },
    rerakuPay: {
      500: '#009E96',
      300: '#008E87',
      200: '#47C9C3',
      100: '#A9DDDA'
    },
    triathlon: {
      500: '#00ADA9',
      300: '#61899C',
      100: '#485A63'
    },
    pay: {
      500: '#00ADA9',
      300: '#E0F1F1'
    },
    payBrandSecondary: {
      500: '#553E26',
      300: 'rgba(85, 62, 38, 0.6)',
    }
  },
  outline: {
    colors: {
      brand: {
        500: '#82ae40',
        300: '#eaf0e3',
      },
      brandSecondary: {
        500: '#553E26',
        300: 'rgba(85, 62, 38, 0.6)',
      }
    }
  },
  components: {
    Drawer,
    Checkbox,
  },
  textStyles: {
    // fontsize
    // トップページ周りで主に使用する
    h1: {
      fontSize: {
        base: '24px',
        lg: '30px',
      },
    },
    // トップページ以外の見出しに相当する
    h2: {
      fontSize: {
        base: '20px',
        lg: '24px',
      },
    },
    // 予約・検索で表示する店舗名
    h3: {
      fontSize: {
        base: '18px',
        lg: '22px',
      },
    },
    // 普通のテキストより目立つ文言で使用する
    h4: {
      fontSize: {
        base: '16px',
        lg: '18px',
      },
    },
    // テキストの基本ベース
    h5: {
      fontSize: {
        base: '14px',
        lg: '16px',
      },
    },
    h6: {
      fontSize: {
        base: '13px',
        lg: '14px',
      },
    },
    h7: {
      fontSize: {
        base: '13px',
        lg: '13ps'
      },
    },
    h8: {
      fontSize: {
        base: '12px',
        lg: '13px',
      },
    },
    h9: {
      fontSize: {
        base: '12px',
        lg: '12px',
      },
    },
    // 目立たせない説明文など
    // 可読性が落ちる為、これより文字を小さくしない
    h10: {
      fontSize: {
        base: '11px',
        lg: '12px',
      },
    },
  }
});
